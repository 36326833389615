import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "./side-bar.scss";
import { visibilityCheck } from "../../utils/visibility-check";
import UserPreferenceContext from "../../contexts/user-preference-context";

const SideBar = ({ open, setSideBarOpen }) => {

  const userPreference = useContext(UserPreferenceContext);

  return (

    <div
      className={`sideMenu menu ${open ? "menuOpen" : open !== null ? "menuClose" : ""} cardShadow`}
    >
      <ul>
        <li className="menuItem headerSmall" >
          <Link to="/" onClick={() => setSideBarOpen(false)}>
            Home
          </Link>
        </li>

        {/* <li className="menuItem headerSmall">
          <Link to="/about-us" onClick={() => setSideBarOpen(false)}> About Us </Link>
        </li> */}
        {/* <li className="menuItem headerSmall">
          <Link to="/solutions" onClick={() => setSideBarOpen(false)}> Solutions </Link>
        </li> */}
        {/* <li className="menuItem headerSmall" style={{ display: visibilityCheck(userPreference, ["T"]) }}>
            <Link to="/domain-fees" onClick={() => setSideBarOpen(false)}>Domain Fees</Link>
          </li> */}
        {/* <li className="menuItem headerSmall" style={{ display: visibilityCheck(userPreference, ["C"]) }}>
            <Link to="/compare-partner-levels" onClick={() => setSideBarOpen(false)}>Partner Levels</Link>
          </li> */}

        <li className="menuItem headerSmall">
          <Link onClick={() => setSideBarOpen(false)} to="/platform">Platform</Link>
        </li>


        <li className="menuItem headerSmall">
          <Link onClick={() => setSideBarOpen(false)} to="/solutions">Solutions</Link>
        </li>

        <li className="menuItem headerSmall">
          <Link onClick={() => setSideBarOpen(false)} to="/partner">Partners</Link>
        </li>

        <li className="menuItem headerSmall">
          <Link onClick={() => setSideBarOpen(false)} to="/pricing">Pricing</Link>
        </li>

        {/* <li className="menuItem headerSmall">
          <Link onClick={() => setSideBarOpen(false)} to="/contact-us"> Contact Us </Link>
        </li> */}

        <li className="menuItem headerSmall">
          <a href="https://community.kzen8.com" target="_blank" rel="noopener noreferrer">
            Community
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SideBar;
