export const solutions = [{
    id: 'innovation',
    title: "Crowdsourced Innovation Platform (GCHCIP)",
    firstParagraph: "Joint collaboration with Gold Coast Hospital and Health Service (GCHHS), part of Queensland Health Australia.",
    secondParagraph: "Created to source and track the progress ideas suggested by any of GCHHS’s 15,000 employees, this solution delivers an end to end system for innovation management applicable to any industry.",
    navigationLink: "/solutions/innovation",
    imageLink: "/images/inovation-platform-2.png",
    heroImageLink: "/images/innovation.webp",
    collaboratorLogoLink: '/images/gch_logo.png',
    definition: "GCHCIP is a highly configurable solution designed to help any organisation target specific issues or topics that are important to them and crowdsource ideas for innovation internally, engage key stakeholders and track the best ideas through to implementation.",
    features: [
        {
            title: 'Provides role-based access to topic managers, moderators and community members with each user only having access to the views and data that pertains to their specific role.'
        },
        {
            title: 'Provides options to tailor the overall workflow of managing topics and how suggestions get rated.'
        },
        {
            title: 'Provides access to the information through an easy to use interface that works seamlessly across mobile, tablet and desktop devices.'
        },
        {
            title: 'Scores are weighted as per the user’s role and experience for the topic in context. This follows on to gamification around league tables for the best ideas, with the winner being promoted into the Progress tab for full project management.'
        },
        {
            title: 'Detailed project management tool with workflows, community features, monthly update reporting, DICE (Duration, Integrity, Commitment, and Effort) scores and milestone/impact reporting. '
        },
        {
            title: 'Performance tracking module provides extended reporting after the project is implemented, offering a simple survey function to get feedback and data around the success of the innovation in the future.'
        }
    ],
    benefits: [
        {
            title: 'Crowdsourcing Ideas',
            body: 'On predetermined topics enables targeted innovation that is aligned to organisation strategies and key issues.'
        },
        {
            title: 'Improved corporate culture',
            body: 'By increasing the involvement of employees and providing feedback, employees will naturally feel their ideas for improvements are being heard.'
        },
        {
            title: 'Single Stop Innovation and Process Management',
            body: 'Provides a front door and brings the entire innovation management workflow and process into one single space, from the initial idea being registered, all the way through to a project management system with milestone reporting.'
        },
        {
            title: 'Improved visibility',
            body: 'Provide visibility on ideas and innovations by enabling reporting based on requirements and templates within your organisation.'
        }
    ]

},
{
    id: 'nursing',
    title: "Nursing Placement Solution (GCHNPS)",
    firstParagraph: "Joint collaboration with Gold Coast Hospital and Health Service (GCHHS), part of Queensland Health Australia.",
    secondParagraph: "Created to streamline the management of over 1,000 nursing placements daily, this solution is an end to end system designed to efficiently manage the planning, pre-placement and day to day processes required for placing student nurses into facilities as part of their hands-on training.",
    navigationLink: "/solutions/nursing",
    imageLink: "/images/nursing-student-placement-platform.png",
    heroImageLink: "/images/nursing.webp",
    collaboratorLogoLink: '/images/gch_logo.png',
    definition: "GCHNPS is an end to end solution designed to efficiently manage the planning, pre-placement and day to day processes required for placing student nurses into facilities as part of their hands-on training.",
    features: [
        {
            title: 'Provides sign-in access to education providers, health service administrators and students as required, with each user only having access to the views and data that pertains to their specific role.'
        },
        {
            title: 'Provides options to tailor the overall workflow of managing nursing placements in line with current procedures within the health service'
        },
        {
            title: 'Provides any number of configurable views of the information to suit each role ensuring users always have access to the information they need'
        },
        {
            title: 'Provides access to the information through an easy to use interface that works seamlessly on mobile, tablet and desktop devices.'
        }
    ],
    benefits: [
        {
            title: 'Improved planning efficiency',
            body: ' Through streamlining the processes between the education providers and health service for both allocating and assigning students to facilities'
        },
        {
            title: 'Improved pre-placement efficiency',
            body: '  Through full automation of the student deed process into a paperless process utilising Docusign, plus management of the onboarding process through a configurable orientation checklist'
        },
        {
            title: 'Improved planning efficiency',
            body: ' Through streamlining the processes between the education providers and health service for both allocating and assigning students to facilities'
        },
        {
            title: 'Improved planning efficiency',
            body: ' Through streamlining the processes between the education providers and health service for both allocating and assigning students to facilities'
        }
    ]


},
{
    id: 'education',
    title: "Medical Student Education Solution",
    firstParagraph: "Joint collaboration with Gold Coast Hospital and Health Service (GCHHS), part of Queensland Health Australia.",
    secondParagraph: "Created to replace a number of spreadsheets and white boards used to coordinate the weekly education programme for over 500 medical students. The system manages a complex matrix of students, locations, doctors and sessions.",
    imageLink: "/images/Bookings.webp",
},
{
    id: 'outpatient',
    title: "Outpatient Utilisation Manager",
    firstParagraph: "Joint collaboration with Gold Coast Hospital and Health Service (GCHHS), part of Queensland Health Australia.",
    secondParagraph: "Created to provide a clearer view of room utilisation in GCHHS’s $1bn per year outpatient department. The enhanced visibility of utilisation this solution delivers translates directly into improved patient care.",
    imageLink: "/images/Rostering.webp",
}]