import React, { useState } from "react";
import "./domain-fees.scss";
import { BsQuestionCircleFill } from "react-icons/bs";
import { TiTick } from "react-icons/ti";
import Alert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Button } from '@mui/material';
import { BsFillArrowRightCircleFill, BsFillCaretDownFill } from 'react-icons/bs';
const DomainFees = () => {


  const [userPreferenceCost, setUserPreferenceCost] = useState('monthly');

  const partnerLevels = [
    {
      title: "Kzen8or - Free Tier",
      subtitle: "Everything you need to give The Kzen8 Platform a go, with no commitment, no credit card required",
      monthlyCost: "$0 AUD",
      annualCost: "$0 AUD",
      features: [
        "Access to the core application design components",
        "Access to all video tutorials",
        "Access to KzenPlay, our free sandbox where you can learn and experiment",
        "Unlimited domains, applications and sub applications with the ability to clone and merge as required",
        "Unlimited application collaborators - Work on applications together",
        "Full security control including two-factor authentication",
        "Full back up and restore functionality with unlimited retention",
        "Earn KzenCredits for referring other Kzen8ors, which can be used to pay for subscriptions and hosting",
        "(Optional) Ability to create live applications (incurs hosting fees - see below)",
      ]
    },
    {
      title: "Professional Kzen8or",
      subtitle: "Create, use and sell your own applications with all design components unlocked",
      monthlyCost: "$50 AUD",
      annualCost: "$500 AUD",
      isRecommeded: true,
      features: [
        "Includes everything in the Free Tier",
        "If you pay annually you get 2 months for free",
        "Access to the full library of application design components",
        "Full version control features with unlimited branches",
        "Choice of hosting locations across the world",
        "Ability to brand your applications using the inbuilt theme designer",
        "Earn KzenCredits for active participation in the Kzen8 Community",
        "5% discount on hosting fees (see below)"
      ]
    },
    {
      title: "Gold & Platinum Kzen8ors",
      subtitle: "For those that want to get serious about generating significant income with The Kzen8 Platform",
      monthlyCost: "Starting from $500 AUD",
      annualCost: "Starting from $5,000 AUD",
      features: [
        "Includes everything in the Professional Tier",
        "If you pay annually you get 2 months for free",
        "Additional hosting fee discounts of up to 25% (see below)",
        "Access to the 'inner circle' of Kzen8",
        "Additional unique ways of earning KzenCredits",
        "Additional unique ways of generating revenue from The Kzen8 Platform",
        "The opportunity to purchase shares and own a slice of Kzen8 as we reach key milestones in the growth of the Kzen8 Community"
      ]
    },
  ];

  const domainFeesSource = [
    {
      category: "Active users- users that have signed in within the last 7 days (daily average over the month)",
      unitName: "5 Users",
      unitCharge: "2.00"
    },
    {
      category: "Database storage (daily average over the month)",
      unitName: "1 Gb",
      unitCharge: "2.00"
    },
    {
      category: "Cache storage (daily average over the month)",
      unitName: "50 Mb",
      unitCharge: "1.00"
    },
    {
      category: "File storage (daily average over the month)",
      unitName: "50 Gb",
      unitCharge: "0.10"
    },
    {
      category: "Processing (actual)",
      unitName: "10 Hours",
      unitCharge: "2.00"

    },
    {
      category: "Video encoding - once per video upload (actual). Encoding a 10 minute video take approximately 1 minute",
      unitName: "30 Minutes",
      unitCharge: "1.00",
    },
    {
      category: "Video streaming (actual)",
      unitName: "2 Gb",
      unitCharge: "2.00",
    },
  ];

  const dedicatedDomainFeesSource = [
    {
      category: "Small Cluster",
      unitName: "Cluster",
      unitCharge: "2,500.00",
    },
    {
      category: "Med duster",
      unitName: "Cluster",
      unitCharge: "5,000.00",
    },
    {
      category: "Large Cluster",
      unitName: "Cluster",
      unitCharge: "10,000.00",
    },
    {
      category: "XLarge Cluster",
      unitName: "Cluster",
      unitCharge: "20,000.00",
    },
    {
      category: "File storage (daily average over the month)",
      unitName: "Gb",
      unitCharge: "0.5",
    },
    {
      category: "Processing (actual)",
      unitName: "Hour",
      unitCharge: "3.00",
    },
    {
      category: "Video encoding - once per video upload (actual)",
      unitName: "Minute",
      unitCharge: "1.00",
    },
    {
      category: "Video streaming (actual)",
      unitName: "Gb",
      unitCharge: "2.00",
    },
  ];

  const kzenEnterpriseDomainFeesSource = [
    {
      category: "Kzen8 Branded",
      unitName: "Environment",
      unitCharge: "30,000.00",
    },
    {
      category: "White Labelled",
      unitName: "Environment",
      unitCharge: "50,000.00",
    }
  ];


  const getCost = (idx) => {
    if (userPreferenceCost === 'monthly') {
      return partnerLevels[idx].monthlyCost;
    }
    else {
      return partnerLevels[idx].annualCost;
    }
  }

  return (
    <>
      <div className="sectionContainer full-width-element">
        <div className="domainFees">

          <div className="topSection">
            <h1 className="bannerHeading tertiary-dark-text no-margin">Start Building<span className="primary-text"> With <span className="tertiary-text">Kzen8</span></span></h1>
            <br />
            <h2 className="header">Kzen8or Subscription Fees</h2>
            <span className="body">Sign up as a Kzen8or and start building amazing business applications with The Kzen8 Platform today.</span>

            <Link to="https://hub.kzen8.com/signup" target='_blank'>
              <Button className="btn btn-tertiary callToAction subtitle2" variant="contained" endIcon={<BsFillArrowRightCircleFill />}>
                Start Building Today
              </Button>
            </Link>

            <div className="preferenceSwitch body">
              <div className={`${userPreferenceCost === 'monthly' ? 'selected' : ''}`} onClick={() => setUserPreferenceCost('monthly')}>Monthly</div>
              <div className={`${userPreferenceCost === 'annual' ? 'selected' : ''}`} onClick={() => setUserPreferenceCost('annual')}>Annually</div>
            </div>
          </div>


          <div className="levelContainer">
            {
              partnerLevels.map((level, num) => (
                <div className={`level ${level.isRecommeded ? 'recommended' : ''}`}>
                  <div className="headerSection" style={{ gridTemplateColumns: '1fr' }}>


                    <h3 class="header title">
                      {level.title + (level.isRecommeded ? '✨' : '')}
                      <span className="body cost">{getCost(num)}</span>
                    </h3>

                    <div style={{ gridTemplateColumns: '1fr' }} className={`subtext body`}>
                      <span style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>{level.subtitle}
                      </span>

                    </div>

                  </div>
                  <div className="bodySection body">




                    {/* <div style={{ gridTemplateColumns: '1fr' }} className={`category alternate`}>
                      <h2 className="header" style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}> {level.cost}
                      </h2>

                    </div> */}

                    <span>What's included</span>

                    <div className="featuresContainer">
                      {level.features.map((feature, idx) => (
                        <div style={{ gridTemplateColumns: '1fr' }} className={`category ${idx % 2 === 0 ? "" : "alternate"}`}>
                          <span style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '8px', textAlign: 'left' }}>

                            <TiTick color="#343e8e" size={32} />
                            {feature}
                            {feature.tooltip ? <span class="tooltip"><BsQuestionCircleFill />
                              <span class="tooltiptext">{feature.tooltip}</span>
                            </span> : ''}
                          </span>

                        </div>
                      ))}
                    </div>

                  </div>

                </div>))
            }
          </div>












        </div>
      </div>


      <div className="usageSection full-width-element">
        <h2 className="header">Hosting Fees</h2>

        <h3 className="subtitle2">

          <span>
            Hosting a live application on Kzen8's servers costs $40 AUD per month and includes over $120 AUD of bundled resources.
            <br /><br />
            If bundled resource limits are exceeded, there are additional fees as shown in the table below.
            <br /><br />

          </span>

          <Alert severity="info" icon={false}>
            <span className="body">
              PLEASE NOTE: The fees shown are maximum fees. Depending on the level of Kzen8or subscription, users receive up to a 30% discount on all their application hosting fees.
              <br /><br /> Furthermore, applications are grouped together into domains and within a domain, resource limits are shared across all applications in the domain, further reducing overall hosting fees.
            </span>
          </Alert>

        </h3>

        <div className="container">
          <div className="headerSection">
            <h3 class="headerSmall">Category</h3>
            <h3 class="headerSmall">Bundled units</h3>
            <h3 class="headerSmall">Excess charge per unit ($AUD)</h3>
          </div>

          <div className="bodySection body">
            {domainFeesSource.map((source, idx) => (
              <div className={`category ${idx % 2 === 0 ? "" : "alternate"}`}>
                <span style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>{source.category}
                  {source.tooltip ? <span class="tooltip"><BsQuestionCircleFill />
                    <span class="tooltiptext">{source.tooltip}</span>
                  </span> : ''}
                </span>
                <span>{source.unitName}</span>
                <span style={{ textAlign: 'center' }}>${source.unitCharge}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="enterpriseSection full-width-element">
        <h2 className="header">Self Hosting and White Labelling</h2>

        <div className="bodySection">
          <span className="body">
            Where applicable, organisations can install their own copy of The Kzen8 Platform on their own AWS account, paying Kzen8 a license fee whilst paying all hosting costs directly to  AWS.
            <br /><br />
            Self hosting is the ultimate solution for both organisations seeking to embrace “citizen development”, as well as consultancies that want to extend their client offering into solution development with their own no-code platform.

            <br /> <br />

            White labelling takes self hosting to the next level by not only allowing for the creation of a truly unique visual experience, but also empowers organisations to extend the functionality of The Kzen8 Platform in an infinite number of ways.


          </span>
        </div>

        <img src="">
        </img>


      </div>
    </>


  );
};

export default DomainFees;
