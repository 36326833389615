import React from 'react'
import './platform.scss';
import Features from '../../sections/features/features';
import EndlessPossibilities from '../../sections/endless-possibilities/endless-possibilities';
import { Link } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Button } from '@mui/material';
import { MdEditSquare } from 'react-icons/md';
import WhatCanYouBuild from '../../sections/what-can-you-build/what-can-you-build';
import LazyLoad from 'react-lazyload';
import { BsFillArrowRightCircleFill, BsFillCaretDownFill } from 'react-icons/bs';

function Platform() {

    const faqs = [{
        title: "What is Kzen8's technology stack?",
        description: `The Kzen8 Platform is 100% cloud-based and offered as either a managed solution or as a self hosted solution. It uses an Angular front end (Javascript library) based Single Page Application (SPA) hosted on AWS. AWS Lambda acts as the application API, connecting the front-end SPA to its backend MongoDb database.
        There are a number of authentication options that include 2 Factor Authentication and Single Sign On using MS Azure Active Directory. The data for each individual application is isolated from all other applications and creators can decide where the data for each application is stored globally. The Kzen8 Platform meets all the requirements of the AWS Well Architected Framework, ensuring that all data is securely encrypted both at rest and in transit.
        `
    },
    {
        title: "Does Kzen8 provide self-hosting options?",
        description: `Where applicable, organisations can host their own copy of The Kzen8 Platform on their own AWS account. 
        Self hosting is the ultimate solution for both organisations seeking to embrace “citizen development”, as well as consultancies that want to extend their client offering into solution development with their own no-code platform. 
        `
    }]
    return (
        <div className="platform">
            <div className='heroSection'>
                <section className='leftSection'>
                    <h1 className="bannerHeading primary-text-colour">
                        <span className="tertiary-dark-text">A True  <span className="primary-text"> No-code <span className="tertiary-text">Solution</span></span></span>
                    </h1>

                    <span className='body'>
                        The Kzen8 Platform empowers anyone to build complex back-office business software within days or weeks all without writing a single line of code.
                    </span>

                    <div className="buttonContainer">
                        <Link to="https://hub.kzen8.com/signup" target='_blank'>
                            <Button className="btn btn-tertiary callToAction subtitle2" variant="contained" endIcon={<BsFillArrowRightCircleFill />}>
                                Start Building Today
                            </Button>
                        </Link>
                    </div>
                    
                </section>
                <section className='rightSection'>
                    <div className="heroImageContainer">
                        <img src="/images/hero-image-commercial.webp" alt="hero-section-image"></img>
                        <img src="/images/Popup1-technical.svg" alt="popup1" key="popup1-technical" className="popup1 scaleIn" />
                        <img src="/images/Popup2-technical.svg" alt="popup1" key="popup2-technical" className="popup2  scaleIn" />
                        <img src="/images/Popup3-technical.svg" alt="popup1" key="popup3-technical" className="popup3  scaleIn" />
                        <img src="/images/Popup4-technical.svg" alt="popup1" key="popup4-technical" className="popup4  scaleIn" />


                    </div>
                </section>
            </div>

            <div className='middlePieceSection full-width-element'>
                <section className='leftSection'>
                    <h2 className='header'>The Perfect Middle Piece</h2>
                    <span className='body'>
                        The Kzen8 Platform fits perfectly between an organisation’s desktop applications and its large enterprise systems, “glueing” systems together, resulting in improved ROI even on existing system investments.
                    </span>
                </section>
                <section className='rightSection'>
                    <LazyLoad offset={-200}>
                        <div className='piece slideUpAnimation animated initially-hidden'>
                            <h2 className='headerSmall'>Enterprise Systems</h2>
                            <img src="/images/oracle.png" />
                            <img src="/images/salesforce.png" />
                            <img src="/images/sap.png" />

                        </div>
                        <div className='piece slideUpAnimation animated delay-1 initially-hidden'>
                            <h2 className='headerSmall'>The Middle</h2>
                            <img src="/images/logo.png" />
                            <span className='subinfo'>
                                When departmental requirements are too big and complex to be solutioned with spreadsheets, but too small for enterprise applications – Kzen8 shines with rapid-build bespoke back-office applications that can be created in just weeks without any prior coding knowledge.
                            </span>

                        </div>
                        <div className='piece slideUpAnimation animated  delay-2 initially-hidden'>
                            <h2 className='headerSmall'>Desktop Applications</h2>
                            <img src="/images/office-365.png" />
                            <img src="/images/gmail.png" />
                            <img src="/images/xero.svg" />
                            <img src="/images/trello.png" />
                            <img src="/images/monday.webp" />

                        </div>
                    </LazyLoad>

                </section>
            </div>

            <div className='integrationSection full-width-element'>
                <div className="background">

                    <img src="/images/Dotted Background.svg" />
                    <div className='linearBackground'>

                    </div>
                </div>

                <section className='leftSection'>
                    <h2 className='header'>Plug and play</h2>
                    <span className='body'>
                        Power your custom web applications with secure, scalable and best-in-class functionality.
                        <br />
                        <br />
                    </span>

                    <span className='body'>
                        Use our out-of-box integrations or implement your own integration to your favourite API by leveraging our webhook and connector functionality.
                    </span>

                </section>

                <section className='rightSection'>

                    <div className="heroImageContainer">
                        <LazyLoad>

                            <img src="/images/entra.png" alt="popup1" key="popup1-technical" className="popup4 integrations scaleIn" />
                            <img src="/images/google_maps.svg" alt="popup1" key="popup1-technical" className="popup5 integrations scaleIn" />
                            <img src="/images/mailchimp.png" alt="popup1" key="popup2-technical" className="popup6 integrations scaleIn" />
                            <img src="/images/stripe.svg" alt="popup1" key="popup3-technical" className="popup7 integrations scaleIn" />
                            <img src="/images/mongodb.svg" alt="popup1" key="popup3-technical" className="popup8 integrations scaleIn" />
                            <img src="/images/docusign.svg" alt="popup1" key="popup3-technical" className="popup9 integrations scaleIn" />
                            <img src="/images/aws-logo.png" alt="popup1" key="popup3-technical" className="popup10 integrations scaleIn" />
                            <img src="/images/twilio.png" alt="popup1" key="popup3-technical" className="popup11 integrations scaleIn" />

                            <div className='hub'>
                                <img src={'/images/logo.png'} alt="logo" />
                            </div>
                        </LazyLoad>

                    </div>

                </section>
            </div>






            <Features />
            <EndlessPossibilities />


            <div className='faqSection'>
                <h2 className='header'>Frequently Asked Questions</h2>

                <div className='faqContainer'>
                    {faqs.map((faq) => (
                        <Accordion className="accordion">
                            <AccordionSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                expandIcon={<BsFillCaretDownFill />}
                            >
                                <h3 className='subtitle2' style={{ margin: 0 }}>{faq.title}</h3>
                            </AccordionSummary>
                            <AccordionDetails className='body'>
                                {faq.description}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            </div>

        </div>
    )
}

export default Platform
